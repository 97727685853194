/* eslint-disable radix */
/* eslint-disable import/prefer-default-export */
import cookies from 'js-cookie';
import { expiredToken, custDataNameCookie, loginRedirect } from '@config';
import { getAdAccountId } from '@modules/login/services/graphql';
import {
    useHardcodedToken, REST_ENDPOINT_ADS, EMAIL_ACCOUNT_ADS, PASSWORD_ACCOUNT_ADS,
} from '@helpers/hardcodedToken';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const setLastPathWithoutLogin = (path) => {
    cookies.set('lastPathNoAuth', path);
    return true;
};

export const getLastPathWithoutLogin = () => {
    const path = cookies.get('lastPathNoAuth');
    if (path && typeof type !== 'undefined' && path !== '') {
        return path;
    }
    const isSeller = !!cookies.getJSON('cdt')?.customer_company_code;
    if (isSeller) {
        return loginRedirect.seller;
    }
    return loginRedirect.admin;
};

export const removeLastPathWithoutLogin = () => {
    cookies.remove('lastPathNoAuth');
};

export const setLogin = (isLogin = 0, expired) => {
    cookies.set('isLogin', isLogin, { expires: expired || expiredToken });
    return 0;
};

export const getLoginInfo = () => {
    const isLogin = cookies.get('isLogin');
    return parseInt(isLogin) || 0;
};

export const removeIsLoginFlagging = () => {
    try {
        cookies.remove(custDataNameCookie);
        cookies.remove('isLogin');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error on removeIsLoginFlagging: ', error);
    }

    // add remove cookies on header and next-cookies
    // base on https://www.npmjs.com/package/next-cookies
    document.cookie = 'foo=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
};

export const getAccountId = () => {
    const { token } = useHardcodedToken({
        url: REST_ENDPOINT_ADS,
        email: EMAIL_ACCOUNT_ADS,
        password: PASSWORD_ACCOUNT_ADS,
    });
    const [fetchAdAccountId, { data, loading }] = getAdAccountId();

    React.useEffect(() => {
        if (token) {
            fetchAdAccountId({
                context: { request: publicRuntimeConfig.GRAPHQL_ADS_NAME, headers: { authorization: `Bearer ${token?.replaceAll('"', '')}` } },
            });
        }
    }, [token]);

    if (loading || !data) {
        return null;
    }
    return data?.getAdAccountId;
};
