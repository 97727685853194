import { withTranslation, useTranslation } from '@i18n';
import { useRouter } from 'next/router';
import { loginRedirect } from '@config';

const Error = (props) => {
    const {
        statusCode, Content, isSeller,
    } = props;
    const router = useRouter();
    const { t } = useTranslation('common');

    if (typeof window !== 'undefined' && isSeller && router.route === '/') {
        router.replace(loginRedirect.seller);
        return <div />;
    }
    const statusCodes = {
        400: `${t('common:bad_request')}`,
        404: `${t('common:page_not_found')}`,
        405: `${t('common:forbidden')}`,
        500: `${t('internal_server_error')}`,
    };

    const infoStatusCodes = {
        400: `${t('common:info_400')}`,
        404: `${t('common:info_404')}`,
        405: `${t('common:info_405')}`,
        500: `${t('common:info_500')}`,
    };

    // eslint-disable-next-line react/destructuring-assignment
    const title = props.title || statusCodes[statusCode] || 'An unexpected error has occurred';
    const detailCode = infoStatusCodes[statusCode];

    return (
        <Content t={t} statusCode={statusCode} detailCode={detailCode} title={title} isSeller={isSeller} />
    );
};

export default withTranslation()(Error);
