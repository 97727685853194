import Head from 'next/head';
import Button from '@common_button';
import { useRouter } from 'node_modules/next/router';
import useStyles from '@modules/theme/pages/style';
import { useTranslation } from '@i18n';

const NoConnection = ({ httpStatusCode = 0 }) => {
    const styles = useStyles();
    const router = useRouter();
    const { t } = useTranslation('common');
    const isStatus5XX = httpStatusCode >= 500 && httpStatusCode <= 599;
    const imgSource = !isStatus5XX ? '/assets/img/offline.svg' : '/assets/img/icon_service_unavailable.svg';

    return (
        <>
            <Head>
                <title>{t('Offline_Connection')}</title>
            </Head>
            <div className={styles.offline}>
                <div className={styles.wrapper}>
                    <img src={imgSource} alt="offline" style={{ maxWidth: 250 }} />
                    {t('Offline') ? (
                        <>

                            {!isStatus5XX ? (
                                <>
                                    <h1 className={styles.h1}>{t('Offline')}</h1>
                                    <div className={styles.desc}>
                                        <h2 className={styles.h2}>{t('Offline_Connection')}</h2>
                                        <span className={styles.info}>{t('Network_Connection_is_Offline')}</span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={styles.desc}>
                                        <span className={styles.info}>{t('common:service_unavailable')}</span>
                                    </div>
                                </>
                            )}
                        </>
                    ) : null}
                    <div className={styles.actions}>
                        <Button
                            buttonType="primary-rounded"
                            onClick={() => router.reload()}
                        >
                            {t('Refresh')}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NoConnection;
