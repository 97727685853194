import _app from '@modules/theme/pages/_app';

// /**
//  * Import global css
//  * */
import '@styles/index.css';
import '@styles/mediaquery.css';
import '@styles/flexboxgrid.min.css';
import '@styles/checkboxtree.css';
import '@styles/intro-js.css';
import '@styles/gjs.css';

export default _app;
