export const PRIMARY = '#BE1F93';
export const PRIMARY_SOFT = '#B92790';
export const PRIMARY_DARK_OLD = '#4D2F82';
export const PRIMARY_DARK = '#000000';
export const SECONDARY = '#841A8B';

export const GRAY_PRIMARY = '#DEDEDE';
export const GRAY_SECONDARY = '#B4B4B4';
export const WHITE = '#FFFFFF';
export const WHITE_IMPORTANT = '#FFFFFF !important';

export const RED = '#ff0000';
export const ORANGE = '#FE5D26';
export const GREEN = '#46954D';
export const BLACK = '#000000';

export const PURPLE = '#BE1F93';
export const GRAY_BG = '#F8F8F8';
export const GRAY_BG_2 = '#ECF0F2';
export const FONT_COLOR = '#ECEFF7';
export const GRAY_LIGHT = '#B1BCDA';
export const GRAY_LIGHT2 = '#E2E6EA';
export const GRAY_LIGHT3 = '#A0A0A0';
export const LIGHT_GRAY = '#F7F7F7';
export const TABLE_GRAY = '#F3F4FA';
export const TEXT_COLOR = '#68779F';
export const TEXT_COLOR2 = '#7a7a7a';
export const TEXT_DANGER = '#B30100';
export const TEXT_GRAY = '#839AAD';
export const ALERT_DANGER = '#F9E5E4';
export const DISABLED = '#BDBDBD';
export const ERROR = '#FF1744';
export const GREEN_ACTIVE = '#A2E876';
export const SUCCESS = '#238636';
export const GREEN_HIGHLIGHT = '#DBF7E4';
export const BLUE_LINK = '#007BDB';

export const PURPLE_STATUS = '#5F27B9';
export const YELLOW_STATUS = '#F7C537';
export const CYAN_STATUS = '#27A8B9';
export const ORANGE_STATUS = '#F74637';

export const BOX_SHADOW = '#4d2f821a';
export const BORDER_TEXT = '#536777';
export const GRAY_TITLE = '#B1BCDB';
export const BORDER_COLOR = '#D2D9DF';

export const YELLOW_PALE = '#FEF3C7';
export const GREEN_PALE = '#D1FAE5';
export const PURPLE_PALE = '#FCEBF9';

export const ORANGE_ICON = '#F59E0B';
export const GREEN_ICON = '#10B981';

export const RED_STATUS_TABLE = '#FFDFDF';
export const RED_STATUS_TABLE_BORDER = '#D80000';
export const YELLOW_STATUS_TABLE = '#FFF9E2';
export const YELLOW_STATUS_TABLE_BORDER = '#FFCD04';
export const GREEN_STATUS_TABLE = '#EBFFE2';
export const GREEN_STATUS_TABLE_BORDER = '#51C519';

export const TRANSPARENT_PURPLE = '#ffcaf1';
export const WHITE_GRAY = '#E5E7EB';

export const NEUTRAL_50 = '#F9FAFB';
export const NEUTRAL_60 = '#52697A';
export const NEUTRAL_100 = '#F3F4F6';
export const NEUTRAL_200 = '#E5E7EB';
export const NEUTRAL_300 = '#D1D5DB';
export const NEUTRAL_400 = '#9CA3AF';
export const NEUTRAL_500 = '#6B7280';
export const NEUTRAL_600 = '#4B5563';
export const NEUTRAL_700 = '#374151';
export const NEUTRAL_800 = '#1F2937';

export const SUCCESS_500 = '#12B76A';
