import Head from 'next/head';
import Button from '@common_button';
import { useRouter } from 'node_modules/next/router';
import useStyles from '@modules/error/pages/default/components/style';

const ErrorContent = (props) => {
    const styles = useStyles();
    const {
        statusCode, detailCode, title, isSeller, t,
    } = props;

    const router = useRouter();

    return (
        <>
            <Head>
                <title>{`${statusCode} ${title}`}</title>
            </Head>
            <div className={styles.error}>
                <div className={styles.wrapper}>
                    <img src="/assets/img/img_404.svg" alt="404" />
                    {statusCode ? (
                        <>
                            <h1 className={styles.h1}>{statusCode}</h1>
                            <div className={styles.desc}>
                                <h2 className={styles.h2}>{title}</h2>
                                <span className={styles.info}>{detailCode}</span>
                            </div>
                        </>
                    ) : null}
                    <div className={styles.actions}>
                        <Button
                            buttonType="primary-rounded"
                            onClick={() => router.push(isSeller ? '/seller/order' : '/')}
                        >
                            {t('common:back_to_home')}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ErrorContent;
