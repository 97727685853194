import makeStyles from '@material-ui/core/styles/makeStyles';
import { PRIMARY } from '@theme_color';

const color_gray = '#4B5563';

const useStyles = makeStyles((theme) => ({
    error: {
        color: '#000',
        background: '#fff',
        height: '100vh',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    body: {
        margin: 0,
    },
    wrapper: {
        margin: 0,
    },
    desc: {
        display: 'block',
    },
    h1: {
        display: 'block',
        color: PRIMARY,
        fontSize: 18,
        fontWeight: 700,
        marginTop: 24,
        marginBottom: 0,
    },
    h2: {
        fontSize: '3rem',
        fontWeight: 700,
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
        },
    },
    info: {
        color: color_gray,
        fontSize: 18,
        marginTop: 10,
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
        [theme.breakpoints.down('xs')]: {
            width: 250,
        },
    },
    actions: {
        marginTop: 40,
        '& button': {
            padding: '14px 26px',
            borderRadius: 6,
            fontSize: '1rem',
            fontWeight: 700,
            textTransform: 'none',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.8rem',
            },
        },
    },
}));

export default useStyles;
