import firebase from 'firebase/app';
import getConfig from 'next/config';

require('firebase/auth');
require('firebase/messaging');
require('firebase/firestore');

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = features.pushNotification.config;

const { publicRuntimeConfig } = getConfig();

const firebaseConfig = {
    apiKey: String(publicRuntimeConfig.FIREBASE_API_KEY),
    authDomain: String(publicRuntimeConfig.FIREBASE_AUTH_DOMAIN),
    projectId: String(publicRuntimeConfig.FIREBASE_PROJECT_ID),
    databaseURL: String(publicRuntimeConfig.FIREBASE_DATABASE_URL),
    storageBucket: String(publicRuntimeConfig.FIREBASE_STORAGE_BUCKET),
    messagingSenderId: String(publicRuntimeConfig.FIREBASE_MESSAGING_SENDER_ID),
    appId: String(publicRuntimeConfig.FIREBASE_APP_ID),
    measurementId: String(publicRuntimeConfig.FIREBASE_MEASURE_ID),
};

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const googleProviderId = firebase.auth.GoogleAuthProvider.PROVIDER_ID;

export default !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
